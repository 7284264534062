<template>
  <b-card body-bg-variant="light"
          class="ma-4"
          header="Team dashboard"
          header-bg-variant="info"
          header-class="color-lighten"
          header-text-variant="light">
    <h4>Proposals submitted during last call:</h4>
    <b-table :busy="is_busy"
             :current-page="current_page"
             :fields="fields"
             :items="proposals"
             :per-page="per_page"
             head-variant="light"
             hover
             responsive
             striped>
      <template v-slot:top-row="{fields}">
        <proposal-filter :id="id"
                         v-model="proposals"
                         :fields="fields"
                         :not_include_key="['actions','ordinalNumber']">
        </proposal-filter>
      </template>

      <template v-slot:cell(ordinalNumber)="row">
        <base-column-ordinal-number v-bind="{index: row.index, perPage:per_page, currentPage:current_page}"/>
      </template>

      <template v-slot:cell(actions)="row">
        <base-icon-button-people @click="go_to_team(row)"/>
      </template>
    </b-table>
    <b-table-footer v-model="loading"
                    :currentPage="current_page"
                    :data_length="proposals.length"
                    :perPage="per_page"
                    @change_page="change_page">
    </b-table-footer>
  </b-card>
</template>

<script>
  import BaseIconButtonPeople from '@/components/baseComponents/baseIcons/BaseIconButtonPeople';
  import bTableFooter from '@/components/b_table_footer';
  import proposalFilter from '@/components/proposal_filter';
  import BaseColumnOrdinalNumber from '@/components/baseComponents/BaseColumnOrdinalNumber';
  import { ordinalNumber } from '@/helpers/commonTableColumns';
  import { includesAny } from '@/helpers';

  export default {
    name: 'team_dashboard',
    components: {
      BaseIconButtonPeople,
      proposalFilter,
      bTableFooter,
      BaseColumnOrdinalNumber,
    },
    data() {
      return {
        id: 1,
        loading: false,
        current_page: 1,
        per_page: 10,
        is_busy: false,
        proposals: [],
        fields: [
          ordinalNumber,
          {
            key: 'document_specification.document_id',
            label: 'ID',
          },
          'title',
          {
            key: 'document_specification.type',
            label: 'Infrastructure',
          },
          'actions',
        ],
      };
    },
    methods: {
      load_data() {
        this.loading = true;
        const call_states = { call_states: 'submissionClosed experiment' };
        this.axios.get(
          '/documents/me/active',
          { params: call_states },
        )
          .then((response) => {
            this.proposals = response.data;
            this.$emit('setup_proposal', this.proposals);
            this.loading = false;
          });
      },
      go_to_team(row) {
        let infrastructure = row.item.document_specification.type;
        // counterintuitively redirecting newest cryoem proposals to synchrotron versions of team_form_wrapped due to
        // unifying teams in cryoem and synchrotron proposals
        // This should be changed after call  `Spring 2024 - 13th` changes state from `experiment to `ended`
        // Instructions of what else should be changed to finalize cryoem teams rework is on Samba disk

        if (infrastructure === 'cryoem') { // delete to finalize cryoem teams rework
          if (!this.areDeprecatedCryoemTeamRolesInProposal(row.item)) infrastructure = 'synchrotron';
        }
        this.$router.push(`/proposals/${row.item._id.$oid}/${infrastructure}/team`);
      },
      areDeprecatedCryoemTeamRolesInProposal(proposal) { // delete to finalize cryoem teams rework
        const deprecatedCryoemTeamRoles = ['experimentalist', 'principle_investigator', 'main_proposer'];
        const teamMembers = proposal.team.team_members;
        return teamMembers.some((member) => includesAny(Object.keys(member.roles), deprecatedCryoemTeamRoles));
      },
      change_page(val) {
        this.current_page = val;
      },
    },
    created() {
      this.load_data();
    },
  };
</script>

<style scoped>

</style>
